/**
 * Define Action loading [spinner], error, and other state status httpRequest
 */
// React import
import { ActionCreator } from 'redux';

// My script
import {
  ERouteAction,
  IActionPropsAuthModal,
  IActionPropsCguUpdate,
  IActionPropsCustom,
  IActionPropsEnterpriseOfferModal,
  IActionPropsForceRefresModule,
  IActionPropsIStateExceptionHandler,
  IActionPropsMaintenance,
  IActionPropsNetworkFailed,
  IActionPropsRSEModal,
  IActionPropsRefreshCart,
  IActionPropsShowAppRating,
  IActionPropsUpgrade,
  IStateAuthModal,
  IStateCguUpdate,
  IStateCustom,
  IStateEnterpriseOfferModal,
  IStateExceptionHandler,
  IStateFailedNetwork,
  IStateForceRefreshModule,
  IStateRSEModal,
  IStateRefreshCart,
  IStateShowAppRating,
  IStateUpdatesMaintenance,
  IStateUpgrade,
} from './types';

// Action to send status spinner Loading
export const stateMaintenance: ActionCreator<IStateUpdatesMaintenance> = (
  state: IActionPropsMaintenance
) => ({
  type: ERouteAction.maintenanceState,
  payload: {
    state,
  },
});

export const stateExceptionHandler: ActionCreator<IStateExceptionHandler> = (
  state: IActionPropsIStateExceptionHandler
) => ({
  type: ERouteAction.exceptionHandler,
  payload: {
    state,
  },
});

export const stateUpgrade: ActionCreator<IStateUpgrade> = (
  state: IActionPropsUpgrade
) => ({
  type: ERouteAction.upgradeState,
  payload: {
    state,
  },
});

export const stateForceRefreshModule: ActionCreator<IStateForceRefreshModule> = (
  state: IActionPropsForceRefresModule
) => ({
  type: ERouteAction.forceRefreshModule,
  payload: {
    state,
  },
});

export const stateRefreshCart: ActionCreator<IStateRefreshCart> = (
  state: IActionPropsRefreshCart
) => ({
  type: ERouteAction.refreshCart,
  payload: {
    state,
  },
});

export const stateAuthModal: ActionCreator<IStateAuthModal> = (
  state: IActionPropsAuthModal
) => ({
  type: ERouteAction.authModal,
  payload: {
    state,
  },
});

export const stateEnterpriseOfferModal: ActionCreator<IStateEnterpriseOfferModal> = (
  state: IActionPropsEnterpriseOfferModal
) => ({
  type: ERouteAction.enterpriseOfferModal,
  payload: {
    state,
  },
});

export const stateRSEModal: ActionCreator<IStateRSEModal> = (
  state: IActionPropsRSEModal
) => ({
  type: ERouteAction.RSEModal,
  payload: {
    state,
  },
});

export const stateCustom: ActionCreator<IStateCustom> = (state: IActionPropsCustom) => ({
  type: ERouteAction.customStatus,
  payload: {
    state,
  },
});

export const stateFailedNetwork: ActionCreator<IStateFailedNetwork> = (
  state: IActionPropsNetworkFailed
) => ({
  type: ERouteAction.networkFailedState,
  payload: {
    state,
  },
});

export const stateCguUpdate: ActionCreator<IStateCguUpdate> = (
  state: IActionPropsCguUpdate
) => ({
  type: ERouteAction.CguUpdateState,
  payload: {
    state,
  },
});

export const stateShowAppRating: ActionCreator<IStateShowAppRating> = (
  state: IActionPropsShowAppRating
) => ({
  type: ERouteAction.showAppRating,
  payload: {
    state,
  },
});
