/**
 * @description
 * Reducer of my Boutique
 *
 * dispatch action authScreen
 *
 */

// React import
import { Reducer } from 'redux';

// My script
// IMPORT TYPE
import { BoutiqueActions, ERouteAction, IBoutiqueState } from './types';

// INTIAL STATE
export const initialState: IBoutiqueState = {
  isShowAlert: false,
  dataNotif: {
    showCartNotif: false,
    id: '0',
  },
};

// Define all action authScreen To dispach in screen
const BoutiqueReducer: Reducer<IBoutiqueState> = (
  state: IBoutiqueState = initialState,
  action
) => {
  switch ((action as BoutiqueActions).type) {
    case ERouteAction.setToggleAlert: {
      return {
        // State
        ...state,

        // Redux Store
        isShowAlert: action.payload.boutique.isShowAlert,
      };
    }

    case ERouteAction.updateCart: {
      return {
        // State
        ...state,
        // Redux Store
        dataNotif: {
          showCartNotif: action.payload.boutique.showCartNotif,
          id: action.payload.boutique.id,
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default BoutiqueReducer;
