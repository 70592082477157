/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orderReference: '',
    isFromMyOrders: false,
    isDefaultChat: false
};

const ChatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setOrderReference: (state, action) => {
            state.orderReference = action.payload;
        },
        setIsFromMyOrders: (state, action) => {
            state.isFromMyOrders = action.payload;
        },
        setIsDefaultChat: (state, action) => {
            state.isDefaultChat = action.payload;
        }
    },
});

export const { setOrderReference, setIsFromMyOrders, setIsDefaultChat } = ChatSlice.actions;
export default ChatSlice.reducer;


