import { useDispatch } from 'react-redux';
import { Navigate, RouteProps } from 'react-router-dom';
import { stateAuthModal } from '../reducers/State/action';
import { EAuthModalTypes, IAuthModal } from '../reducers/State/types';
import { AppDispatch } from '../store/store';
import { IRequireAuthProps } from './types';

export type ProtectedRouteProps = {
  redirectTo?: string;
} & RouteProps;

const RequireAuth = (props: IRequireAuthProps) => {
  const { children, isAuthenticated } = props;
  const dispatch = useDispatch<AppDispatch>();

  if (!isAuthenticated) {
    const authModal: IAuthModal = {
      isOpen: true,
      type: EAuthModalTypes.LOGIN,
    };

    dispatch(stateAuthModal(authModal));
    return <Navigate to="/" />;
  }

  return children;
};

export default RequireAuth;
