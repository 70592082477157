/**
 * DEFINE TYPE IN AuthReducer.tsx
 *
 */

// React import
import { Action } from 'redux';

export enum EAuthModalTypes {
  LOGIN = 'login',
  REGISTER = 'register',
}

// Define all redux action in authscreen
export enum ERouteAction {
  loadingState = '@@state/LOADING_STATUS',
  maintenanceState = '@@state/MAINTENANCE_STATUS',
  upgradeState = '@@state/UPGRADE_STATUS',
  customStatus = '@@state/CUSTOM_STATUS',
  networkFailedState = '@@state/NETWORK_FAILED_STATE',
  exceptionHandler = '@@state/EXCEPTION_HANDLER',
  showAppRating = '@@state/SHOW_APPRATING',
  CguUpdateState = '@@state.CGU_UPDATE',
  authModal = '@@state/AUTH_MODAL',
  enterpriseOfferModal = '@@state/ENTERPRISE_OFFER_MODAL',
  RSEModal = '@@state/RSE_MODAL',
  forceRefreshModule = '@@state/FORCE_REFRESH_MODULE',
  refreshCart = '@@state/REFRESH_CART',
}

// User for state passing in switch action
export interface IStateLoading {
  maintenanceMode: IMaintenanceMode;
  isUpgrade: boolean;
  customMessage: ICustomMessage;
  networkFailedMessage: INetWorkFailed;
  exceptionHandlerError: IExceptionHandlerError;
  showAppRatingState: IStateShowAppRatingProps;
  cguUpdateMessage: ICguUpdate;
  authModal: IAuthModal;
  enterpriseOfferModal: IEnterpriseOfferModal;
  RSEModal: IRSEModal;
  forceRefresh: boolean;
  refreshCart: boolean;
}

export interface IAuthModal {
  isOpen: boolean;
  type: EAuthModalTypes;
}

export interface IEnterpriseOfferModal {
  isOpen: boolean;
}

export interface IRSEModal {
  isOpen: boolean;
}
export interface IExceptionHandlerError {
  isError: boolean;
  error: unknown;
}
export interface IStateShowAppRatingProps {
  isShow: boolean;
}
export interface INetWorkFailed {
  isFailed: boolean;
  data: {
    title: string;
    description: string;
  };
}
export interface IShowAppRating {
  isShow: boolean;
}
export interface IMaintenanceMode {
  isMaintenance: boolean;
  title: string;
  description: string;
}

export interface ICustomMessage {
  isCustomMessage: boolean;
  data: {
    title: string;
    description: string;
    urlAction: string;
    buttonName: string;
  };
}

// DEFINE ACTION REDUX
export interface IActionProps {
  type: string;
  isLoading: boolean;
}

export interface IActionPropsMaintenance {
  type: string;
  maintenanceMode: IMaintenanceMode;
}

export interface IActionPropsIStateExceptionHandler {
  type: string;
  exceptionHandlerError: IExceptionHandlerError;
}

export interface IActionPropsExceptionHandler {
  type: string;
  exceptionHandlerError: IExceptionHandlerError;
}

export interface IActionPropsUpgrade {
  type: string;
  isUpgrade: boolean;
}

export interface IActionPropsAuthModal {
  type: string;
  showAuthModal: IAuthModal;
}

export interface IActionPropsEnterpriseOfferModal {
  type: string;
  showEnterpriseOfferModal: IEnterpriseOfferModal;
}

export interface IActionPropsRSEModal {
  type: string;
  showRSEModal: IRSEModal;
}

export interface IActionPropsForceRefresModule {
  type: string;
  forceRefresh: boolean;
}

export interface IActionPropsRefreshCart {
  type: string;
  refreshCart: boolean;
}

export interface IActionPropsCustom {
  type: string;
  customMessage: ICustomMessage;
}

export interface IActionPropsNetworkFailed {
  type: string;
  networkFailedMessage: INetWorkFailed;
}

export interface IActionPropsShowAppRating {
  type: string;
  showAppRatingState: IShowAppRating;
}

export interface IStateReducer {
  state: IStateLoading;
}

export interface IStateMaintenance {
  state: {
    maintenanceMode: IMaintenanceMode;
  };
}
export interface IStateCustomMessage {
  state: {
    customMessage: ICustomMessage;
  };
}

export interface IStateNetworkFailed {
  state: {
    networkFailedMessage: INetWorkFailed;
  };
}

export interface IStateCguUpdateSelector {
  state: {
    cguUpdateMessage: ICguUpdate;
  };
}

export interface IStateUpgradePayload {
  state: {
    isUpgrade: boolean;
  };
}
export interface IStateExceptionErrorPayload {
  state: {
    exceptionHandlerError: IExceptionHandlerError;
  };
}
export interface IStateShowAppRatingPayload {
  state: {
    showAppRatingState: IStateShowAppRatingProps;
  };
}

// Type of loading spinner
export interface IStateUpdateStatus extends Action {
  type: ERouteAction;
  payload: {
    state: IActionProps;
  };
}

// Type of loading spinner
export interface IStateUpdatesMaintenance extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsMaintenance;
  };
}

export interface IStateExceptionHandler extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsExceptionHandler;
  };
}

export interface IStateUpgrade extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsUpgrade;
  };
}

export interface IStateAuthModal extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsAuthModal;
  };
}

export interface IStateEnterpriseOfferModal extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsEnterpriseOfferModal;
  };
}

export interface IStateRSEModal extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsRSEModal;
  };
}

export interface IStateForceRefreshModule extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsForceRefresModule;
  };
}

export interface IStateRefreshCart extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsRefreshCart;
  };
}

export interface IStateCustom extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsCustom;
  };
}

export interface IStateFailedNetwork extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsNetworkFailed;
  };
}

export interface IStateCguUpdate extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsCguUpdate;
  };
}

export interface IActionPropsCguUpdate {
  type: string;
  cguUpdateMessage: ICguUpdate;
}

export interface ICguUpdate {
  isUpdate: boolean;
}
export interface IStateShowAppRating extends Action {
  type: ERouteAction;
  payload: {
    state: IActionPropsShowAppRating;
  };
}
