import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contactSubjects: [],
  countdownNeighborhood: [],
  sponsorConfig: [],
  suggestionSearch: [],
  wayType: [],
  refreshAppIfVersionIsOutdated: false,
};

const ConfigSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setJson: (state, action) => {
      const {
        contact_subjects,
        countdonwNeighborhood,
        sponsor_config,
        way_type,
        suggestion_search,
      } = action.payload;

      state.contactSubjects = contact_subjects;
      state.countdownNeighborhood = countdonwNeighborhood;
      state.sponsorConfig = sponsor_config;
      state.wayType = way_type;
      state.suggestionSearch = suggestion_search;
    },
    setrefreshAppIfVersionIsOutdated: (state, action) => {
      state.refreshAppIfVersionIsOutdated = action.payload;
    },
  },
});

export const { setJson, setrefreshAppIfVersionIsOutdated } = ConfigSlice.actions;

export default ConfigSlice.reducer;
