import { useDispatch } from 'react-redux';
import { stateAuthModal } from '../reducers/State/action';
import { EAuthModalTypes, IAuthModal } from '../reducers/State/types';
import { AppDispatch } from '../store/store';

const OpenLoginModal = (props: { children: JSX.Element }) => {
  const { children } = props;
  const dispatch = useDispatch<AppDispatch>();

  const authModal: IAuthModal = {
    isOpen: true,
    type: EAuthModalTypes.LOGIN,
  };

  dispatch(stateAuthModal(authModal));

  return children;
};

export default OpenLoginModal;
