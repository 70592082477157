/* eslint-disable */
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  orderName: '',
  userEmail: '',
  submitSuccess: false,
  isLoading: false,
};

const IssueReportingSlice = createSlice({
  name: 'issue-reporting',
  initialState,
  reducers: {
    setOrderName: (state, action) => {
      state.orderName = action.payload;
    },
    setUserEmail: (state, action) => {
      state.userEmail = action.payload;
    },
    setSubmitSuccess: (state, action) => {
      state.submitSuccess = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {setOrderName, setUserEmail, setSubmitSuccess, setIsLoading} =
  IssueReportingSlice.actions;
export default IssueReportingSlice.reducer;
