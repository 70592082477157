/**
 * Fetch api request
 */

// My modules
import packageJson from '../../package.json';
import { getUserInfo, logout } from '../api/auth.request';
import { IProsGetUserInfo } from '../api/types';
import { IEnvironmentConfigData } from '../config/api.config';
import { getConfigEnvironement } from '../config/environement.settings';
import { environment } from '../config/server.config';
import {
  stateCustom,
  stateExceptionHandler,
  stateFailedNetwork,
  stateMaintenance,
} from '../reducers/State/action';
import { setrefreshAppIfVersionIsOutdated } from '../store/features/config.slice';
import { EStatusCode, IPropsRequest } from './types';

export function parseJSON(response: any) {
  return new Promise((resolve, reject) =>
    response
      .json()
      .then((json: any) => {
        if (response.ok) {
          resolve({
            status: response.status,
            ok: response.ok,
            json,
          });
        } else {
          reject({
            status: response.status,
            ok: response.ok,
            json,
          });
        }
      })
      .catch(() => {
        if (response.ok) {
          resolve({
            status: response.status,
            ok: response.ok,
            json: {},
          });
        } else reject(response);
      })
  );
}

const buildHeaders = (
  overideRequest: boolean | undefined,
  url: string,
  method: string
) => {
  const userData: IProsGetUserInfo = getUserInfo();

  const env = getConfigEnvironement();

  const externalHost = overideRequest ? env.overideEndPoint : env.endPoint;

  return process.env.NODE_ENV == 'development'
    ? {
        Accept: 'application/json; charset=utf-8',
        'Content-Type': 'application/json;charset=UTF-8',
        'Cache-Control': 'no-cache',
        [environment.ng.httpClient.headers.appOrigin.name]: 'website_pub',
        [environment.ng.httpClient.headers.appVersion.name]: '3.0.0',
        [environment.ng.httpClient.headers.appVersionDevice.name]: packageJson.version,
        [environment.ng.httpClient.headers.apiAcceptVersion.name]: 'v2.01',
        [environment.ng.httpClient.headers.authLogin.name]: userData?.email || '',
        [environment.ng.httpClient.headers.apiEndpoint.name]: url,
        [environment.ng.httpClient.headers.apiExternalHost.name]: externalHost,
        [environment.ng.httpClient.headers.apiMethod.name]: method,
        ...(userData && { Authorization: `Bearer ${userData.accessToken}` }),
      }
    : {
        Accept: 'application/json; charset=utf-8',
        'Content-Type': 'application/json;charset=UTF-8',
        [environment.ng.httpClient.headers.appOrigin.name]: 'website_pub',
        [environment.ng.httpClient.headers.apiAcceptVersion.name]: 'v2.01',
        [environment.ng.httpClient.headers.authLogin.name]: userData?.email || '',
        ...(userData && { Authorization: `Bearer ${userData.accessToken}` }),
      };
};

const buildMethod = (method: string) => {
  return process.env.NODE_ENV == 'development' ? 'POST' : method.toUpperCase();
};

const buildUrl = (
  env: IEnvironmentConfigData,
  overideRequest: boolean | undefined,
  endpoint: string
) => {
  const externalHost = overideRequest ? env.overideEndPoint : env.endPoint;
  const url =
    process.env.NODE_ENV == 'development' ? env.nodeApiUrl : externalHost + endpoint;

  return url;
};

const httpRequest = async (props: IPropsRequest) => {
  try {
    const { dispatch, url, method, data, overideRequest } = props;

    // Get env
    const env = getConfigEnvironement();
    const apiUrl = buildUrl(env, overideRequest, url);

    return fetch(apiUrl, {
      method: buildMethod(method),
      headers: buildHeaders(overideRequest, url, method),
      body: method !== 'get' ? JSON.stringify(data) : null,
    })
      .then(parseJSON)
      .then((response: any) => {
        // Return response
        if (response.ok) {
          return response;
        } else return { hasError: true, data: response.json };
      })
      .catch(async responseJson => {
        const response = responseJson;

        // Dispatch error in app
        const status = response.status;

        // Check Status returned of server
        if (status === EStatusCode.forbidden) {
          //  token expired user logout
          // force disconnect user
          await logout();
        } else if (status === EStatusCode.maintenance) {
          dispatch(
            stateMaintenance({
              maintenanceMode: {
                isMaintenance: true,
                title: 'Mode maintenance',
                description: 'Service en mode maintenance',
              },
            })
          );
        } else if (status === EStatusCode.conflict) {
          if (response?.json?.metadata?.customErrorCode === EStatusCode.upgradeApp) {
            dispatch(setrefreshAppIfVersionIsOutdated(true));
          }
        } else if (status === EStatusCode.unauthorized) {
          return { hasError: true, data: response.json };
        } else if (status === EStatusCode.customMessage) {
          // show custom message of app

          dispatch(
            stateCustom({
              customMessage: {
                isCustomMessage: true,
                data: {
                  title: response.json.data.title,
                  description: response.json.data.description,
                  urlAction: response.json.data.buttonUrl,
                  buttonName: response.json.data.buttonName,
                },
              },
            })
          );
        } else if (response.json) {
          return { hasError: true, data: response.json };
        } else {
          // network failed
          dispatch(
            stateFailedNetwork({
              networkFailedMessage: {
                isFailed: true,
                data: {
                  title: '',
                  description: '',
                },
              },
            })
          );
        }
      });
  } catch (error) {
    // dispatch event error
    props.dispatch(
      stateExceptionHandler({
        exceptionHandlerError: { isError: true, error: error },
      })
    );
  }
};

export default httpRequest;
