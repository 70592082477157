import { Action } from 'redux';

// Define all redux action in authscreen
export enum EUserRouteAction {
  userUpdate = '@@user/UPDATE_USER',
}

export interface IUserState {
  conciergerie: {
    id: number;
    name: string;
    odooConciergerieId: number;
    timezone: string;
  } | null;
  defaultDeliveryAddress: {
    building: string | null;
    city: string;
    coordinate: {
      longitude: number;
      latitude: number;
    };
    details: string | null;
    digicode: string | null;
    door: string | null;
    elevator: string | null;
    floor: string | null;
    id: number;
    isEnterpriseAddress: boolean;
    keyNumber: string | null;
    name: string | null;
    street: string | null;
    sublocality: string | null;
    zip: string | null;
  } | null;
  billingAddress: {
    building: string | null;
    city: string;
    coordinate: {
      longitude: number;
      latitude: number;
    };
    details: string | null;
    digicode: string | null;
    door: string | null;
    elevator: string | null;
    floor: string | null;
    id: number;
    isEnterpriseAddress: boolean;
    keyNumber: string | null;
    name: string | null;
    street: string | null;
    sublocality: string | null;
    zip: string | null;
  } | null;
  developerMode: boolean;
  email: string | null;
  id: number | null;
  firstName: string | null;
  lastName: string | null;
  isClientPro: boolean | null;
  linkedToConciergerie: boolean;
  marketingSubscription: boolean | null;
  marketingSubscriptionDate: string | null;
  memberStatus: string | null;
  mobileNumber: string | null;
  mobileNumberValid: boolean;
  odooConciergerieId: number | null;
  odooId: number | null;
  roles: string[];
  type: string | null;
  creationDate: string | null;
  cguDate: string | null;
  cguId: number | null;
  cguValid: boolean | null;
}

// DEFINE ACTION REDUX
export interface IActionProps {
  user: IUserState;
}

// *********************************************
// Declare our action types using our interface,
// *********************************************
// I use the `@@context/ACTION_TYPE` convention for naming action types.
export interface IUpdateUser extends Action {
  type: EUserRouteAction;
  payload: {
    user: IActionProps;
  };
}

// Down here, we'll create a discriminated union type of all actions which will be used
// for our reducer.
export type UserActions = IUpdateUser;
