/**
 * DEFINE TYPE IN AuthReducer.tsx
 *
 */

// React import
import { Action } from 'redux';

/*export interface RouteAction {
	[index: number]: string
}*/

// Define all redux action in authscreen
export enum ERouteAction {
  setToggleAlert = '@@boutique/SHOW_ALERT',
  updateCart = '@@boutique/CART',
}

// User for state passing in switch action
export interface IBoutiqueState {
  isShowAlert: boolean;
  dataNotif: {
    showCartNotif: boolean;
    id: string;
  };
}

// Params props in function
export interface IAuthStateProps {
  boutique: IBoutiqueState;
}

// DEFINE ACTION REDUX
export interface IActionProps {
  type: string;
  isShowAlert: boolean;
}

export interface IActionPropsUpdateCart {
  type: string;
  showCartNotif: boolean;
}

// *********************************************
// Declare our action types using our interface,
// *********************************************
// I use the `@@context/ACTION_TYPE` convention for naming action types.
// SEND STATUS TOGGGLE IN BOUTIQUE ALERT COMMAND
export interface IBoutiqueUpdateToggle extends Action {
  type: ERouteAction;
  payload: {
    boutique: IActionProps;
  };
}

export interface IBoutiqueUpdateCart extends Action {
  type: ERouteAction;
  payload: {
    boutique: IActionPropsUpdateCart;
  };
}

// Down here, we'll create a discriminated union type of all actions which
// will be used for our reducer.
export type BoutiqueActions = IBoutiqueUpdateToggle;
