/**
 * DEFINE TYPE IN AuthReducer.tsx
 *
 */

// React import
import { Action } from 'redux';

// Define all redux action in authscreen
export enum ERouteAction {
  authUpdate = '@@auth/UPDATE_STATUS',
}

/* Defining the default state of the reducer. */
export interface DefaultRootState {
  auth: {
    loggedIn: boolean;
  };
}

// User for state passing in switch action
export interface IAuthState {
  loggedIn: boolean;
}

// Params props in function
export interface IAuthStateProps {
  auth: IAuthState;
}

// DEFINE ACTION REDUX
export interface IActionProps {
  type: string;
  loggedIn: boolean;
}

// *********************************************
// Declare our action types using our interface,
// *********************************************
// I use the `@@context/ACTION_TYPE` convention for naming action types.
// SEND STATUS AUTH IN INDEXSCREEN
export interface IAuthUpdateStatus extends Action {
  type: ERouteAction;
  payload: {
    auth: IActionProps;
  };
}

// Down here, we'll create a discriminated union type of all actions which will be used
// for our reducer.
export type AuthActions = IAuthUpdateStatus;
