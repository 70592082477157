import { Reducer } from 'redux';
import { EUserRouteAction, IUserState, UserActions } from './types';

// INTIAL STATE
export const userInitialState: IUserState = {
  conciergerie: null,
  defaultDeliveryAddress: null,
  billingAddress: null,
  developerMode: false,
  email: null,
  id: null,
  firstName: null,
  lastName: null,
  isClientPro: null,
  marketingSubscription: null,
  marketingSubscriptionDate: null,
  linkedToConciergerie: false,
  memberStatus: null,
  mobileNumber: null,
  mobileNumberValid: false,
  odooConciergerieId: null,
  odooId: null,
  roles: [],
  type: null,
  creationDate: null,
  cguDate: null,
  cguId: null,
  cguValid: null,
};

// Define all action authScreen To dispach in screen
const UserReducer: Reducer<IUserState> = (
  state: IUserState = userInitialState,
  action
) => {
  switch ((action as UserActions).type) {
    case EUserRouteAction.userUpdate: {
      return {
        // State
        ...state,

        // Redux Store
        ...action.payload.user,
      };
    }

    default: {
      return state;
    }
  }
};

export default UserReducer;
