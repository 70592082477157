import '@fontsource/montserrat';
import { createTheme } from '@mui/material/styles';
import themesConfig from './config/themes.config';

export const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat',
    body1: {
      fontSize: '0.9rem',
      lineHeight: '1.1rem',
      color: '#2e2e2e',
      '@media (max-width:600px)': {
        fontSize: '0.8rem',
        lineHeight: '1rem',
      },
    },
    h1: {
      fontWeight: 'bold',
      fontSize: '1.8rem',
      color: '#2e2e2e',
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h2: {
      fontWeight: 'bold',
      fontSize: '1.5rem',
      color: '#2e2e2e',
      '@media (max-width:600px)': {
        fontSize: '1.2rem',
      },
    },
    h3: {
      fontWeight: 'bold',
      fontSize: '1.1rem',
      color: '#2e2e2e',
      '@media (max-width:600px)': {
        fontSize: '0.9rem',
      },
    },
    h4: {
      fontWeight: 'bold',
      fontSize: '1rem',
      color: '#2e2e2e',
      '@media (max-width:600px)': {
        fontSize: '0.8rem',
      },
    },
  },
  palette: {
    primary: {
      main: themesConfig.APP_YELLOW,
      contrastText: '#2e2e2e',
    },
    secondary: {
      main: '#2e2e2e',
      contrastText: '#ffffff',
    },
    tertiary: {
      main: '#ffffff',
      contrastText: '#2e2e2e',
      dark: '#F5F5F5',
    },
    text: {
      primary: '#2e2e2e',
    },
  },
});
declare module '@mui/material/styles/createPalette' {
  interface Palette {
    tertiary: PaletteColor;
  }
  interface PaletteOptions {
    tertiary: PaletteColorOptions;
  }
}
