/**
 * @description
 * Reducer of my Screen Auth
 *
 * dispatch action authScreen
 *
 */

// React import
import { Reducer } from 'redux';
import { getIfAuthUser } from '../../api/auth.request';

// My script
// IMPORT TYPE
import { AuthActions, ERouteAction, IAuthState } from './types';

// INTIAL STATE
export const initialState: IAuthState = {
  loggedIn: getIfAuthUser(),
};

// Define all action authScreen To dispach in screen
const AuthReducer: Reducer<IAuthState> = (state: IAuthState = initialState, action) => {
  switch ((action as AuthActions).type) {
    case ERouteAction.authUpdate: {
      return {
        // State
        ...state,

        // Redux Store
        loggedIn: action.payload.auth.loggedIn,
      };
    }

    default: {
      return state;
    }
  }
};

export default AuthReducer;
