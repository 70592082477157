/* eslint-disable */
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  orderRatingAverage: 0,
};

const OrdersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrderRatingAverage: (state, action) => {
      state.orderRatingAverage = action.payload;
    },
  },
});

export const {setOrderRatingAverage} = OrdersSlice.actions;
export default OrdersSlice.reducer;
