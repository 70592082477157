/**
 * Define user actions
 */

import { ActionCreator } from 'redux';
import { EUserRouteAction, IActionProps, IUpdateUser } from './types';

// Action to send status user AUTH
export const updateUser: ActionCreator<IUpdateUser> = (user: IActionProps) => ({
  type: EUserRouteAction.userUpdate,
  payload: {
    user,
  },
});
