/**
 * @description
 * Reducer of my Screen Auth
 *
 * dispatch action authScreen
 *
 */

// React import
import { Reducer } from 'redux';

// My script
// IMPORT TYPE
import {
  EAuthModalTypes,
  ERouteAction,
  IStateLoading,
  IStateUpdateStatus,
} from './types';

// INTIAL STATE
export const initialState: IStateLoading = {
  maintenanceMode: {
    isMaintenance: true,
    title: '',
    description: '',
  },
  isUpgrade: false,
  forceRefresh: false,
  refreshCart: true,
  authModal: {
    isOpen: false,
    type: EAuthModalTypes.LOGIN,
  },
  enterpriseOfferModal: {
    isOpen: false,
  },
  RSEModal: {
    isOpen: false,
  },
  customMessage: {
    isCustomMessage: false,
    data: {
      title: '',
      description: '',
      urlAction: '',
      buttonName: '',
    },
  },
  networkFailedMessage: {
    isFailed: false,
    data: {
      title: '',
      description: '',
    },
  },
  cguUpdateMessage: {
    isUpdate: false,
  },
  exceptionHandlerError: {
    isError: false,
    error: {},
  },
  showAppRatingState: {
    isShow: false,
  },
};

// Define all action loadingScreen To dispach in screen
const StateReducer: Reducer<IStateLoading> = (
  state: IStateLoading = initialState,
  action
) => {
  switch ((action as IStateUpdateStatus).type) {
    case ERouteAction.maintenanceState: {
      return {
        // State
        ...state,
        // Redux Store
        maintenanceMode: action.payload.state.maintenanceMode,
      };
    }

    case ERouteAction.exceptionHandler: {
      return {
        ...state,
        exceptionHandlerError: action.payload.state.exceptionHandlerError,
      };
    }

    case ERouteAction.upgradeState: {
      return {
        // State
        ...state,
        // Redux Store
        isUpgrade: action.payload.state.isUpgrade,
      };
    }

    case ERouteAction.forceRefreshModule: {
      return {
        // State
        ...state,
        // Redux Store
        forceRefresh: action.payload.state.forceRefresh,
      };
    }

    case ERouteAction.refreshCart: {
      return {
        // State
        ...state,
        // Redux Store
        refreshCart: action.payload.state.refreshCart,
      };
    }

    case ERouteAction.authModal: {
      return {
        // State
        ...state,
        // Redux Store
        authModal: action.payload.state,
      };
    }

    case ERouteAction.enterpriseOfferModal: {
      return {
        // State
        ...state,
        // Redux Store
        enterpriseOfferModal: action.payload.state,
      };
    }

    case ERouteAction.RSEModal: {
      return {
        // State
        ...state,
        // Redux Store
        RSEModal: action.payload.state,
      };
    }

    case ERouteAction.customStatus: {
      return {
        // State
        ...state,
        // Redux Store
        customMessage: action.payload.state.customMessage,
      };
    }

    case ERouteAction.networkFailedState: {
      return {
        // State
        ...state,
        // Redux Store
        networkFailedMessage: action.payload.state.networkFailedMessage,
      };
    }

    case ERouteAction.CguUpdateState: {
      return {
        // State
        ...state,
        // Redux Store
        cguUpdateMessage: action.payload.state.cguUpdateMessage,
      };
    }

    case ERouteAction.showAppRating: {
      return {
        // State
        ...state,
        // Redux Store
        showAppRatingState: action.payload.state.showAppRatingState,
      };
    }

    default: {
      return state;
    }
  }
};

export default StateReducer;
