import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import capitalize from 'lodash/capitalize';
import React from 'react';
import { useTranslation } from 'react-i18next';
import themesConfig from '../../../config/themes.config';

interface Props {
  open: boolean;
  handleRefresh: () => void;
}

const UpdateAppModal: React.FC<Props> = ({ open, handleRefresh }) => {
  const { t } = useTranslation('C_REFRESH_MODAL');

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{t('TITLE')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('DESCRIPTION')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleRefresh}
          color="primary"
          autoFocus
          sx={{
            backgroundColor: themesConfig.APP_YELLOW,
            borderRadius: '4px',
            borderWidth: '2px',
            borderColor: themesConfig.APP_YELLOW,
            color: '#fff',
            fontWeight: 'bold',
          }}
        >
          {capitalize(t('BUTTON_TEXT'))}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateAppModal;
