/**
 * Define Action in AuthScreen
 */
// React import
import { ActionCreator } from 'redux';

// My script
import { ERouteAction, IActionProps, IAuthUpdateStatus } from './types';

// Action to send status user AUTH
export const updateStatus: ActionCreator<IAuthUpdateStatus> = (auth: IActionProps) => ({
  type: ERouteAction.authUpdate,
  payload: {
    auth,
  },
});
