import { ERouteApi } from '../api/routes.definitions';

export interface IPropsRequest {
  dispatch: any; // !! Important to dispatch event
  url: ERouteApi | string;
  method: string;
  data: any;
  header?: string;
  token?: string;
  overideRequest?: boolean;
}

// Status code of serveur
export enum EStatusCode {
  success = 200,
  successCreated = 201,
  noContent = 204,
  unauthorized = 401,
  forbidden = 403,
  conflict = 409,
  customMessage = 418,
  error = 500,
  maintenance = 503,
  upgradeApp = 1201,
}
