import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IOrderStep, IOrderSubStep, IOrders } from '../../../api/orderv3.types';
import { EOrderStepStatus } from '../../../enums';

export interface OrderStepState {
  selectedOrder: IOrders | null;
}

const initialState: OrderStepState = {
  selectedOrder: null,
};

function addCurrentSubSteps(
  state: Draft<OrderStepState>,
  orderSteps: IOrderStep[]
): void {
  if (!state.selectedOrder) return;

  const orderStepsWithCurrentSub = (orderSteps || []).map(orderStep => ({
    ...orderStep,
    currentSubStep: getCurrentSubStep(orderStep),
  }));

  state.selectedOrder.orderSteps = orderStepsWithCurrentSub;
}

function getCurrentSubStep(orderStep: IOrderStep): IOrderSubStep {
  for (const subStep of orderStep.steps) {
    switch (subStep.status) {
      case EOrderStepStatus.FAILED:
      case EOrderStepStatus.INACTIVE:
      case EOrderStepStatus.ACTIVE:
      case EOrderStepStatus.PENDING:
        return subStep;
      case EOrderStepStatus.DONE:
      default:
        continue;
    }
  }
  return orderStep.steps[orderStep.steps.length - 1];
}

export const orderDetailSlice = createSlice({
  name: 'orderDetail',
  initialState: initialState,
  reducers: {
    setSelectedOrderSteps: (state, action: PayloadAction<IOrderStep[]>) => {
      addCurrentSubSteps(state, action.payload);
    },
    setSelectedOrder: (state, action: PayloadAction<IOrders | null>) => {
      state.selectedOrder = action.payload;
      addCurrentSubSteps(state, state.selectedOrder?.orderSteps || []);
    },
    setSelectedOrderStatus: (state, action: PayloadAction<IOrders['status']>) => {
      if (state.selectedOrder) {
        state.selectedOrder.status = action.payload;
      }
    },
  },
});

export const { setSelectedOrderSteps, setSelectedOrder, setSelectedOrderStatus } =
  orderDetailSlice.actions;
