import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const PaymentValidationRedirection = (props: { children: JSX.Element }) => {
  const { children } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  const orderId = searchParams.get('orderId');
  const operation = searchParams.get('operation');

  useEffect(() => {
    setSearchParams(
      {
        ...(orderId && { orderId }),
        ...(operation && { operation }),
      },
      { replace: true, state }
    );
  }, []);

  return children;
};

export default PaymentValidationRedirection;
