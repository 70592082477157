/* eslint-disable array-bracket-spacing */
/* eslint-disable max-len */
import { FC, Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { refreshUser } from '../api/auth.request';
import { trackScreenView } from '../components/firebase/firebase-analytics';
import LoadingContainer from '../components/loading/containers/loading.containers';
import { getConfigEnvironement } from '../config/environement.settings';
import {
  AlertContextProvider,
  CreditCardContextProvider,
  SocketContextProvider,
} from '../contexts';
import { AppRoutePath } from '../enums';
import { AppRouteId } from '../enums/app-route-id.enum';
import { AppDispatch, RootState } from '../store/store';
import OpenLoginModal from './open-login-modal';
import OpenRegisterModal from './open-register-modal';
import PaymentValidationRedirection from './payment-validation-redirection';
import RequireAuth from './require-auth';

// my import
const Cart = lazy(
  () =>
    import('../modules/cart/containers/cart.containers' /* webpackChunkName: "cart" */)
);
const Category = lazy(
  () =>
    import(
      '../modules/offer-category/containers/offer.category.containers' /* webpackChunkName: "category" */
    )
);
const Home = lazy(
  () =>
    import('../modules/home/containers/home.containers' /* webpackChunkName: "home" */)
);
const LandingPage = lazy(
  () =>
    import(
      '../modules/landing-page/containers/landing-page.containers' /* webpackChunkName: "landingPage" */
    )
);
const OrderHistory = lazy(
  () =>
    import(
      '../modules/order-history/containers/order.history.containers' /* webpackChunkName: "orderHistory" */
    )
);
const OrderHistoryItem = lazy(
  () =>
    import(
      '../modules/order-history-item/containers/order-history-item-screen.container' /* webpackChunkName: "orderHistoryItem" */
    )
);
const OfferMenu = lazy(
  () =>
    import(
      '../modules/offer-menu/containers/offer-menu.containers' /* webpackChunkName: "offerMenu" */
    )
);
const EditAddressDelivery = lazy(
  () =>
    import(
      '../modules/edit-address-delivery/containers/edit.address.delivery.screen.containers' /* webpackChunkName: "editAddressDelivery" */
    )
);
const AddAddressDelivery = lazy(
  () =>
    import(
      '../modules/add-address-delivery/containers/add.address.delivery.screen.containers' /* webpackChunkName: "addAddressDelivery" */
    )
);
const AddAddressDeliveryNext = lazy(
  () =>
    import(
      '../modules/add-address-delivery-next/containers/add.address.delivery.next.screen.containers' /* webpackChunkName: "addAddressDeliveryNext" */
    )
);
const AccountUserInfoScreen = lazy(
  () =>
    import(
      '../modules/account-user-info/containers/account.user.info.screen.containers' /* webpackChunkName: "accountUserInfo" */
    )
);
const SettingPaymentMethodScreen = lazy(
  () =>
    import(
      '../modules/settings-payment-method/containers/settings.payment.method.screen.containers' /* webpackChunkName: "settingPaymentMethod" */
    )
);
const ViewAddressDeliveryScreen = lazy(
  () =>
    import(
      '../modules/view-address-delivery/containers/view.address.delivery.screen.containers' /* webpackChunkName: "viewAddressDelivery" */
    )
);
const PrivateLifeScreen = lazy(
  () =>
    import(
      '../modules/private-life/containers/private.life.screen.containers' /* webpackChunkName: "privateLife" */
    )
);
const ContactScreen = lazy(
  () =>
    import(
      '../modules/contact-screen/containers/contact.screen.containers' /* webpackChunkName: "contact" */
    )
);
const UpdatePasswordScreen = lazy(
  () =>
    import(
      '../modules/update-password/containers/update.password.screen.containers' /* webpackChunkName: "updatePassword" */
    )
);
const RewardScreen = lazy(
  () =>
    import(
      '../modules/reward-screen/containers/reward.screen.containers' /* webpackChunkName: "reward" */
    )
);
const PasswordResetScreen = lazy(
  () =>
    import(
      '../modules/password-reset/containers/password.reset.screen.containers' /* webpackChunkName: "passwordReset" */
    )
);
const SearchScreen = lazy(
  () =>
    import(
      '../modules/search-offer/container/search.offer.container' /* webpackChunkName: "searchOffer" */
    )
);
const EnterpriseOffer = lazy(
  () =>
    import(
      '../modules/enterprise-offer/containers/enterprise-offer.containers' /* webpackChunkName: "enterpriseOffer" */
    )
);
const OrderingMethod = lazy(
  () =>
    import(
      '../modules/ordering-method/containers/ordering.method.containers' /* webpackChunkName: "orderingMethod" */
    )
);
const OrderingPayment = lazy(
  () =>
    import(
      '../modules/ordering-payment/containers/order-terms.containers' /* webpackChunkName: "orderingPayment" */
    )
);
const PaymentValidation = lazy(
  () =>
    import(
      '../modules/payment-validation/containers/payment-validation.containers' /* webpackChunkName: "paymentValidation" */
    )
);

const NavigationIndex: FC = () => {
  const isAuthenticated = useSelector((state: RootState) => state.auth.loggedIn);
  const isMaintenance = useSelector(
    (state: RootState) => state.state.maintenanceMode.isMaintenance
  );
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const config = getConfigEnvironement();

  const init = async () => {
    setIsLoading(true);
    await refreshUser(dispatch);
    setIsLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    trackScreenView(location);
  }, [location]);

  const requireAuthProps = {
    isAuthenticated,
  };

  if (isLoading) {
    return <LoadingContainer />;
  } else if (isMaintenance) {
    return (
      <img
        src={'/images/liquidation-judiciaire.png'}
        style={{ height: '100%', width: '100%' }}
      />
    );
  } else {
    return (
      <Suspense fallback={<LoadingContainer />}>
        <Routes>
          <Route
            id={AppRouteId.HOME}
            path={AppRoutePath.HOME}
            element={isAuthenticated ? <Home /> : <LandingPage />}
          />
          <Route
            id={AppRouteId.LOGIN}
            path={AppRoutePath.LOGIN}
            element={
              isAuthenticated ? (
                <Navigate to={AppRoutePath.HOME} />
              ) : (
                <OpenLoginModal>
                  <LandingPage />
                </OpenLoginModal>
              )
            }
          />
          <Route
            id={AppRouteId.REGISTER}
            path={AppRoutePath.REGISTER}
            element={
              isAuthenticated ? (
                <Navigate to={AppRoutePath.HOME} />
              ) : (
                <OpenRegisterModal>
                  <Navigate to={AppRoutePath.HOME} />
                </OpenRegisterModal>
              )
            }
          />
          <Route
            id={AppRouteId.OFFER_MENU}
            path={AppRoutePath.OFFER_MENU}
            element={
              <RequireAuth {...requireAuthProps}>
                <OfferMenu />
              </RequireAuth>
            }
          />
          <Route
            id={AppRouteId.ORDER_METHOD}
            path={AppRoutePath.ORDER_METHOD}
            element={
              <RequireAuth {...requireAuthProps}>
                <OrderingMethod />
              </RequireAuth>
            }
          />
          <Route
            id={AppRouteId.ORDER_PAYMENT}
            path={AppRoutePath.ORDER_PAYMENT}
            element={
              <RequireAuth {...requireAuthProps}>
                <CreditCardContextProvider>
                  <OrderingPayment />
                </CreditCardContextProvider>
              </RequireAuth>
            }
          />
          <Route
            id={AppRouteId.CATEGORY}
            path={AppRoutePath.CATEGORY}
            element={
              <RequireAuth {...requireAuthProps}>
                <Category {...{ isTag: false, isEnterpriseOffer: false }} />
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.USER_ACCOUNT_INFO}
            path={AppRoutePath.USER_ACCOUNT_INFO}
            element={
              <RequireAuth {...requireAuthProps}>
                <AccountUserInfoScreen />
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.PAYMENT_METHOD}
            path={AppRoutePath.PAYMENT_METHOD}
            element={
              <RequireAuth {...requireAuthProps}>
                <CreditCardContextProvider>
                  <SettingPaymentMethodScreen />
                </CreditCardContextProvider>
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.DELIVERY_ADDRESS}
            path={AppRoutePath.DELIVERY_ADDRESS}
            element={
              <RequireAuth {...requireAuthProps}>
                <ViewAddressDeliveryScreen />
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.PRIVATE_LIFE}
            path={AppRoutePath.PRIVATE_LIFE}
            element={
              <RequireAuth {...requireAuthProps}>
                <PrivateLifeScreen />
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.CONTACT}
            path={AppRoutePath.CONTACT}
            element={
              <RequireAuth {...requireAuthProps}>
                <ContactScreen />
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.UPDATE_PASSWORD}
            path={AppRoutePath.UPDATE_PASSWORD}
            element={
              <RequireAuth {...requireAuthProps}>
                <UpdatePasswordScreen />
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.PASSWORD_RESET}
            path={AppRoutePath.PASSWORD_RESET}
            element={<PasswordResetScreen />}
          />

          <Route
            id={AppRouteId.VOUCHER}
            path={AppRoutePath.VOUCHER}
            element={
              <RequireAuth {...requireAuthProps}>
                <RewardScreen />
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.SPONSORSHIP}
            path={AppRoutePath.SPONSORSHIP}
            element={
              <RequireAuth {...requireAuthProps}>
                <RewardScreen />
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.EDIT_DELIVERY_ADDRESS}
            path={AppRoutePath.EDIT_DELIVERY_ADDRESS}
            element={
              <RequireAuth {...requireAuthProps}>
                <EditAddressDelivery />
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.ADD_DELIVERY_ADDRESS}
            path={AppRoutePath.ADD_DELIVERY_ADDRESS}
            element={
              <RequireAuth {...requireAuthProps}>
                <AddAddressDelivery />
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.ADD_DELIVERY_ADDRESS_END}
            path={AppRoutePath.ADD_DELIVERY_ADDRESS_END}
            element={
              <RequireAuth {...requireAuthProps}>
                <AddAddressDeliveryNext />
              </RequireAuth>
            }
          />

          <Route
            id={AppRouteId.ORDER_HISTORY}
            path={AppRoutePath.ORDER_HISTORY}
            element={
              <RequireAuth {...requireAuthProps}>
                <OrderHistory />
              </RequireAuth>
            }
          />
          <Route
            id={AppRouteId.ORDER_HISTORY_DETAILS}
            path={AppRoutePath.ORDER_HISTORY_DETAILS}
            element={
              <RequireAuth {...requireAuthProps}>
                <AlertContextProvider>
                  <SocketContextProvider
                    socketDomain={config.webSocketDomain}
                    socketNamespace="orders"
                    socketOptions={{ path: '/wss/ms-order-v3' }}
                  >
                    <OrderHistoryItem />
                  </SocketContextProvider>
                </AlertContextProvider>
              </RequireAuth>
            }
          />
          <Route
            id={AppRouteId.BUSINESS_OFFER}
            path={AppRoutePath.BUSINESS_OFFER}
            element={
              <RequireAuth {...requireAuthProps}>
                <EnterpriseOffer />
              </RequireAuth>
            }
          />
          <Route
            id={AppRouteId.CATEGORY_TAG}
            path={AppRoutePath.CATEGORY_TAG}
            element={
              <RequireAuth {...requireAuthProps}>
                <Category {...{ isTag: true, isEnterpriseOffer: false }} />
              </RequireAuth>
            }
          />
          <Route
            id={AppRouteId.CATEGORY_BUSINESS_OFFER}
            path={AppRoutePath.CATEGORY_BUSINESS_OFFER}
            element={
              <RequireAuth {...requireAuthProps}>
                <Category {...{ isTag: false, isEnterpriseOffer: true }} />
              </RequireAuth>
            }
          />
          <Route
            id={AppRouteId.SEARCH}
            path={AppRoutePath.SEARCH}
            element={
              <Suspense fallback={<LoadingContainer />}>
                <RequireAuth {...requireAuthProps}>
                  <SearchScreen />
                </RequireAuth>
              </Suspense>
            }
          />
          <Route
            id={AppRouteId.SHOPPING_CART}
            path={AppRoutePath.SHOPPING_CART}
            element={
              <RequireAuth {...requireAuthProps}>
                <Cart />
              </RequireAuth>
            }
          />
          <Route
            id={AppRouteId.PAYMENT_VALIDATION}
            path={AppRoutePath.PAYMENT_VALIDATION}
            element={
              <RequireAuth {...requireAuthProps}>
                <PaymentValidationRedirection>
                  <PaymentValidation />
                </PaymentValidationRedirection>
              </RequireAuth>
            }
          />
          <Route
            id={AppRouteId.OTHER}
            path={AppRoutePath.OTHER}
            element={<Navigate to={AppRoutePath.HOME} />}
          />
        </Routes>
      </Suspense>
    );
  }
};

export default NavigationIndex;
