/* eslint-disable */
/**
 * @description
 * Combine all reducer [modules, components] in UNIQUE store
 *
 */

// React import
// Reducer property plugins
import {combineReducers, configureStore} from '@reduxjs/toolkit';

// My script
// Import all reducer defined in module or component
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import AuthReducer from '../reducers/Auth/auth.reducer';
import BoutiqueReducer from '../reducers/Boutique/boutique.reducer';
import StateReducer from '../reducers/State/state.reducer';
import UserReducer from '../reducers/User/user.reducer';
import ChatSlice from './features/chat.slice';
import ConfigSlice from './features/config.slice';
import IssueReportingSlice from './features/issue-reporting.slice';
import OrdersSlice from './features/orders.slice';
import { orderDetailSlice } from './features';

const reducer = combineReducers({
  auth: AuthReducer,
  state: StateReducer, // Dispach loading spinner
  boutique: BoutiqueReducer,
  user: UserReducer,
  chat: ChatSlice,
  issueReporting: IssueReportingSlice,
  config: ConfigSlice,
  orders: OrdersSlice,
  orderDetail: orderDetailSlice.reducer,
})

// Create store for all reducer action
const store = configureStore({
  reducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
