/**
 * Method of localstorage
 */

// My custom import
import { EStorageKey } from '../config/definition.storage.key';

// Store data in storage local app
export function storeData(data: any, key: EStorageKey) {
  try {
    const jsonValue = JSON.stringify(data);

    localStorage.setItem(key, jsonValue);

    return;
  } catch (e) {
    // saving error
  }
}

// Get data in storage local app
export function getData(key: EStorageKey) {
  try {
    const jsonValue = localStorage.getItem(key);

    return jsonValue != null ? JSON.parse(jsonValue) : null;
  } catch (e) {
    // error reading value
  }
}

// Remove data in storage local app
export function removeData(key: EStorageKey) {
  try {
    return localStorage.removeItem(key);
  } catch (e) {
    // error reading value
  }
}
