import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setrefreshAppIfVersionIsOutdated } from '../../../store/features/config.slice';
import { AppDispatch, RootState } from '../../../store/store';
import UpdateAppModal from '../components/update-app-modal.components';

const UpdateAppModalContainer: React.FC = () => {
  const [open, setOpen] = useState(false);
  const refreshAppIfVersionIsOutdated = useSelector(
    (state: RootState) => state.config.refreshAppIfVersionIsOutdated
  );

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    setOpen(refreshAppIfVersionIsOutdated);
  }, [refreshAppIfVersionIsOutdated]);

  const handleRefresh = () => {
    dispatch(setrefreshAppIfVersionIsOutdated(false));
    window.location.reload();
  };

  return <UpdateAppModal open={open} handleRefresh={handleRefresh} />;
};

export default UpdateAppModalContainer;
